import { compactObject, serialize, getCurrentUrl } from './helpers'

/**
 * Retrieve state with JSON parsing
 * @param {string} key - Key in localStorage
 * @returns {object|null} - Parsed JSON object
 */
export function getState(key) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }

  const data = localStorage.getItem(key)
  if (!data) return null
  return JSON.parse(data)
}

/**
 * Save state with JSON serialization
 * @param {string} key - Key in localStorage
 * @param {object} data - Data to save (JSON object)
 * @returns {object|null} - Saved data
 */
export function setState(key, data) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }
  data = compactObject(data)
  if (!data || !Object.keys(data).length) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, serialize(data))
  }
  return data
}

/**
 * Retrieve state without JSON parsing
 * @param {string} key - Key in localStorage
 * @returns {string|null} - Raw string value
 */
export function getRawState(key) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }
  return localStorage.getItem(key) || null
}

/**
 * Save state without JSON serialization
 * @param {string} key - Key in localStorage
 * @param {string} value - Raw string value to save
 */
export function setRawState(key, value) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }
  if (typeof value !== 'string' || !value.trim()) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }
}

export function removeItemFromLocalStorage(key) {
  if (localStorage !== undefined) {
    localStorage.removeItem(key)
  }
}

// Attempts to remove an item from localStorage and logs failure if any
export function safelyRemoveItemFromLocalStorage(key) {
  try {
    removeItemFromLocalStorage(key)
  } catch (error) {
    logError('Failed to remove item from localStorage', { key, error })
  }
}

// Retrieves an object containing all local storage items
export function getAllLocalStorageItems() {
  try {
    const localStorageItems = {}
    const storage = localStorage

    if (storage) {
      for (let i = 0; i < storage.length; i++) {
        const key = storage.key(i)
        if (key) {
          localStorageItems[key] = getLocalStorageItem(key)
        }
      }
    }

    return localStorageItems
  } catch {
    return {}
  }
}

/**
 * Helper function to set a cookie for 30 days or expire it.
 * @param {string} name - The cookie name.
 * @param {string} value - The cookie value.
 * @param {number} [days=30] - Number of days until expiration. Use negative value to expire immediately.
 */
export const setCookie = (name, value, days = 30) => {
  const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString()
  const currentUrl = getCurrentUrl()
  
  /* Debug logging - commented out for production
  if (currentUrl.hostname && currentUrl.hostname.includes('oatsdev')) {
    console.error('==== SETTING COOKIE ====');
    console.error(`Current hostname: ${currentUrl.hostname}`);
    console.error(`Cookie name: ${name}`);
    console.error(`Cookie value length: ${value ? value.length : 0}`);
    console.error(`Cookie value prefix: ${value ? value.substring(0, 10) + '...' : 'NULL'}`);
  }
  */
  
  // UPDATED COOKIE APPROACH:
  // Now setting all cookies at the top-level domain for cross-subdomain sharing
  // This ensures cookies work across all subdomains (www, app, checkout, etc.)
  // See ENG-1534 for implementation details
  // Cookie migration logic added in ENG-1535
  
  // Get the root domain to use for the cookie
  const rootDomain = getRootDomain(currentUrl.hostname);
  
  // Check if this cookie already exists (could be domain-specific or top-level)
  const existingValue = getCookie(name);
  
  // If we're not explicitly setting a value but one exists, use the existing value
  // This helps migrate domain-specific cookies to top-level domain
  if (!value && existingValue) {
    value = existingValue;
  }
  
  // Set cookie with domain attribute to enable cross-subdomain sharing
  const cookieString = `${name}=${value}; path=/; expires=${expires}; Secure; SameSite=None; domain=.${rootDomain}`;
  
  try {
    // First, clear any domain-specific version of this cookie
    // This expires the cookie for the current domain only, not the root domain
    document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    
    // Now set the cookie at the top-level domain
    document.cookie = cookieString;
    
    /* Debug for oatsdev only - commented out for production
    if (currentUrl.hostname && currentUrl.hostname.includes('oatsdev')) {
      console.error(`Set cookie with string: ${name}=... (${value ? value.length : 0} chars)`);
    }
    
    // Test cookie variations were previously here
    // Now we're only setting the main _cat cookie for production
    */
  } catch (e) {
    /* Error logging - uncomment for troubleshooting
    if (currentUrl.hostname && currentUrl.hostname.includes('oatsdev')) {
      console.error(`Error setting cookie: ${e.message}`);
      console.error(`Error stack: ${e.stack}`);
      console.error('==== SETTING COOKIE FAILED ====');
    }
    */
  }
}

/**
 * Remove the deprecated _rodeo_visitor_id cookie
 * This cookie is no longer used but may still exist in users' browsers
 * We remove it from both domain-specific and top-level domains
 * 
 * @see ENG-1536 for implementation details
 * @deployment-marker 2025-04-07-17:56:00
 */
export const removeDeprecatedVisitorCookie = () => {
  // Prevent tree-shaking by adding a non-removable side effect
  window._cruiseLastRun = {
    time: new Date().toISOString(),
    feature: 'cookie-cleanup-v6'
  };
  
  try {
    // 1. First clear localStorage and global variable
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('_rodeo_visitor_id');
      localStorage.removeItem('_rodeo_visitor_Id');
      
      if (window.RodeoVisitorId) {
        window.RodeoVisitorId = null;
      }
    }
    
    // 2. Get domain information
    const currentUrl = getCurrentUrl();
    const rootDomain = getRootDomain(currentUrl.hostname);
    
    // 3. Find existing cookies to determine their attributes
    const allCookies = document.cookie.split(';')
      .map(cookie => cookie.trim())
      .filter(cookie => 
        cookie.indexOf('_rodeo_visitor_id=') === 0 || 
        cookie.indexOf('_rodeo_visitor_Id=') === 0
      );
    
    // Store information for debugging
    window._cruiseLastRun.cookiesFound = allCookies;
    
    // 4. Common paths where cookies might be set
    const commonPaths = ['/', '/a', '/a/cruise', '/cart', '/checkout'];
    
    // 5. Delete using multiple strategies
    const expireDate = 'Thu, 01 Jan 1970 00:00:00 GMT';
    const cookieNames = ['_rodeo_visitor_id', '_rodeo_visitor_Id'];
    
    // First strategy: Try all common paths with both domain variations
    cookieNames.forEach(name => {
      // Domain-specific cookies with different paths
      commonPaths.forEach(path => {
        // With SameSite=None;Secure (most common for these cookies)
        document.cookie = `${name}=; path=${path}; expires=${expireDate}; SameSite=None; Secure`;
        
        // Without SameSite attribute (fallback)
        document.cookie = `${name}=; path=${path}; expires=${expireDate}`;
      });
      
      // Top-level domain cookies with different paths
      commonPaths.forEach(path => {
        // With SameSite=None;Secure
        document.cookie = `${name}=; path=${path}; expires=${expireDate}; SameSite=None; Secure; domain=.${rootDomain}`;
        
        // Without SameSite
        document.cookie = `${name}=; path=${path}; expires=${expireDate}; domain=.${rootDomain}`;
      });
    });
    
    // Second strategy: Final overwrite with multiple values to be certain
    // Top-level domain with path=/
    document.cookie = `_rodeo_visitor_id=; path=/; expires=${expireDate}; SameSite=None; Secure; domain=.${rootDomain}`;
    document.cookie = `_rodeo_visitor_Id=; path=/; expires=${expireDate}; SameSite=None; Secure; domain=.${rootDomain}`;
    
    // Domain-specific with path=/
    document.cookie = `_rodeo_visitor_id=; path=/; expires=${expireDate}; SameSite=None; Secure`;
    document.cookie = `_rodeo_visitor_Id=; path=/; expires=${expireDate}; SameSite=None; Secure`;
    
    // 6. Verify results
    const remainingCookies = document.cookie.split(';')
      .map(cookie => cookie.trim())
      .filter(cookie => 
        cookie.indexOf('_rodeo_visitor_id=') === 0 || 
        cookie.indexOf('_rodeo_visitor_Id=') === 0
      );
    
    window._cruiseLastRun.cookiesRemaining = remainingCookies;
    window._cruiseLastRun.successful = remainingCookies.length === 0;
  } catch (e) {
    // Just record the error, don't throw
    window._cruiseLastRun.error = e.message;
  }
}

/**
 * Extract the root domain from a hostname
 * Used for setting cookies at the top-level domain to enable cross-subdomain sharing
 * 
 * Examples:
 * - www.example.com -> example.com
 * - app.example.com -> example.com
 * - checkout.example.com -> example.com
 * - store.myshopify.com -> myshopify.com (special case)
 * - example.com -> example.com (already root domain)
 * 
 * @param {string} hostname - The hostname to extract the root domain from
 * @return {string} The root domain
 */
const getRootDomain = hostname => {
  // Special case for myshopify.com domains - always use myshopify.com
  if (hostname.includes('myshopify.com')) {
    return 'myshopify.com'
  }

  const parts = hostname.split('.')

  // If domain has more than 2 parts (e.g., www.oatsovernight.com or portal.oatsovernight.com)
  if (parts.length > 2) {
    // Get the root domain (e.g., oatsovernight.com)
    // This ensures cookies can be shared across subdomains
    return parts.slice(-2).join('.')
  }

  return hostname // Already a root domain (e.g., example.com)
}

/**
 * Helper function to get all cookies as an object or retrieve a specific cookie by name.
 * @param {string} [name] - The cookie name to retrieve. If omitted, returns all cookies as an object.
 * @returns {string|Object|null} - The cookie value if a name is provided, an object of all cookies if omitted, or null if the cookie does not exist.
 */
export const getCookie = name => {
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=')
    if (key && value) {
      acc[key] = decodeURIComponent(value)
    }
    return acc
  }, {})

  return cookies[name] || null
}

export function setAccessToken(accessToken) {
  /* Debug for oatsdev only - commented out for production
  if (window.location.hostname && window.location.hostname.includes('oatsdev')) {
    console.error('🍪 setAccessToken CALLED');
    console.error(`Token provided: ${accessToken ? 'YES (length: ' + accessToken.length + ')' : 'NO'}`);
    console.error(`Call stack: ${new Error().stack}`);
  }
  
  // Debug for oatsdev store only
  if (window.location.hostname.includes('oatsdev')) {
    console.error('==== CLIENT-SIDE DEBUG FOR OATSDEV ====');
    console.error(`Setting _cat cookie with token: ${accessToken ? accessToken.substring(0, 10) + '...' : 'NULL'}`);
    console.error(`Token length: ${accessToken ? accessToken.length : 0}`);
    console.error(`Current cookies BEFORE setting: ${document.cookie}`);
    
    // Log cookies that might already be set
    const existingCat = getCookie('_cat');
    if (existingCat) {
      console.error(`Existing _cat cookie found: ${existingCat.substring(0, 10)}... (${existingCat.length} chars)`);
    } else {
      console.error('No existing _cat cookie found');
    }
  }
  */
  
  if (!accessToken) {
    /* Debug log
    if (window.location.hostname.includes('oatsdev')) {
      console.error('⚠️ Not setting cookie - no access token provided');
    }
    */
    return;
  }
  
  try {
    // Set the cookie
    setCookie('_cat', accessToken, 30);
    
    /* Verification logging - uncomment for troubleshooting
    if (window.location.hostname && window.location.hostname.includes('oatsdev')) {
      console.error('✅ setCookie called successfully');
      
      // Verify if cookie was actually set
      setTimeout(() => {
        const verifiedCookie = getCookie('_cat');
        console.error(`Cookie verification after setAccessToken:`);
        console.error(`- _cat: ${verifiedCookie ? 'SET ✅' : 'NOT SET ❌'} ${verifiedCookie ? `(${verifiedCookie.length} chars)` : ''}`);
        console.error(`- Matches original: ${verifiedCookie === accessToken ? 'YES ✅' : 'NO ❌'}`);
        console.error(`All cookies AFTER setting: ${document.cookie}`);
        console.error('==== CLIENT-SIDE DEBUG FOR OATSDEV COMPLETED ====');
      }, 500);
    }
    */
  } catch (e) {
    /* Error logging - uncomment for troubleshooting
    if (window.location.hostname && window.location.hostname.includes('oatsdev')) {
      console.error('❌ Error setting cookie:', e.message);
      console.error(`Error stack: ${e.stack}`);
      console.error('==== CLIENT-SIDE DEBUG FOR OATSDEV FAILED ====');
    }
    */
  }
}
